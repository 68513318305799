<template>
  <div class="wrapper">
    <div class="animated fadeIn">

      <BRow>
        <BCol>
          <BCard header-tag="header">
            <div slot="header">
              <BIcon icon="collection-fill"/> <strong> 사용자 정보 </strong>
              <div class="card-header-actions">
                <small class="text-muted">사용자 정보를 설정 합니다</small>
              </div>
            </div>
            <BForm @submit="userSubmit">
              <BRow>
                <BCol>
                  <BFormGroup class="mb-1" id="form-01" label="ID" label-cols="3" label-for="input-id" label-align="right">
                    <BFormInput id="input-id"
                                v-model="user.user_id"
                                type="text"
                                placeholder="User ID를 입력하세요"
                                :disabled="actionMode==='put'"
                                required/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-010" label="이름" label-cols="3" label-for="display-name" label-align="right">
                    <BFormInput id="display-name"
                                v-model="user.name"
                                type="text"
                                placeholder="이름을 입력 하세요"
                                required/>
                  </BFormGroup>
                  <BFormGroup v-if="!isAdmin" class="mb-1" id="form-02" label="현재 비밀번호" label-cols="3" label-for="input-current-pwd" label-align="right">
                    <BFormInput id="input-current-pwd"
                                v-model="originPassword"
                                :state="validationOriginPassword"
                                type="password"
                                placeholder="Password"
                                :disabled="actionMode==='post'"
                                :required="actionMode==='put'"/>

                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-03" label="새로운 비밀번호" label-cols="3" label-for="input-new-pwd" label-align="right">
                    <BFormInput id="input-new-pwd"
                                v-model="newPassword"
                                :state="validationPassword"
                                type="password"
                                placeholder="새로운 Password 6~50자 입력"
                                :required="actionMode==='post'"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-04" label="새로운 비밀번호 확인" label-cols="3" label-for="input-re-pwd" label-align="right">
                    <BFormInput id="input-re-pwd"
                                v-model="rePassword"
                                :state="validationRePassword"
                                type="password"
                                :required="actionMode==='post'"/>
                  </BFormGroup>

                  <BFormGroup class="mb-1" id="form-06" label="소속 팀" label-cols="3" label-for="input-user-dept" label-align="right">
                    <BFormInput id="input-user-dept"
                                v-model="user.dept"
                                type="text"
                                placeholder="소속팀 명 입력"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-07" label="전화" label-cols="3" label-for="input-phone-no" label-align="right">
                    <BFormInput id="input-phone-no"
                                type="text"
                                v-model="user.phone_no"
                                placeholder="전화 번호 입력"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-17" label="휴대전화" label-cols="3" label-for="input-mobile-no" label-align="right">
                    <BFormInput id="input-mobile-no"
                                type="text"
                                v-model="user.mobile_no"
                                placeholder="휴대전화 번호 입력"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-08" label="E-MAIL" label-cols="3" label-for="input-email" label-align="right">
                    <BFormInput id="input-email"
                                v-model="user.email"
                                type="text"/>
                  </BFormGroup>
                  <BFormGroup class="mb-1" id="form-41" label="권한" label-cols="3" label-for="input-user-role" label-align="right">
                    <BFormSelect id="input-user-role"
                                 v-model="user.role"
                                 :options="userRoleOptions"
                                 :disabled="!isAdmin"
                                 required/>
                  </BFormGroup>
                  <BRow>
                    <BCol class="text-right">
                      <b-button size="lg" type="submit" variant="primary"
                                :disabled="user.user_id===null">저 장</b-button>
                    </BCol>
                  </BRow>
                </BCol>
              </BRow>

            </BForm>
          </BCard>
        </BCol>
      </BRow>

    </div>
  </div>
</template>

<script>
    //-------------------------------------------------------------------------------------------------
    // import moment from 'moment';
    import {
      alertModal,
      alertSuccess,
      alertError,
      apiCall,
      apiMultiPart,
      cloneVar,
      confirmModal,
      getNotice
    } from '../../common/utils';
    import qs from 'querystring';
    import moment from "moment";


    const _user = {
      user_no   : null,
      user_id   : null,
      pwd_hash  : null,
      name      : null,
      dept      : null,
      role      : null,
      email     : null,
      phone_no  : null,
      mobile_no : null,
      reg_dt    : null,
      upd_dt    : null,
      reg_id    : null,
      upd_id    : null,
      password  : null,
    };

    //----------------------------------------------------------------------------------------------------
    export default {
        name: 'UserInfo',
        data () {
            return {
              user: null,
              originPassword: '',
              newPassword: '',
              rePassword: '',

              userRoleOptions: [
                  {value: 'ROLE_USER', text:'사용자'},
                  {value: 'ROLE_ADMIN', text:'관리자'}
              ],

              actionMode: 'put',
              isAdmin: ($user.role==='ROLE_ADMIN')?true:false

            }
        },
        computed: {
          validationOriginPassword(){
            return (this.originPassword!=='' && this.originPassword.length > 5);
          },
          validationPassword(){
            return (this.newPassword.length > 5 && this.newPassword===this.rePassword);
          },
          validationRePassword(){
            return this.rePassword.length > 5 && this.newPassword===this.rePassword;
          }
        },

        created(){
          this.user = cloneVar(_user);
          this.getUserInfo();
        },


        methods: {

          async userSubmit(evt){
            evt.preventDefault();

            if( this.newPassword!=="" || this.rePassword!=="" ){

              if( this.newPassword!==this.rePassword ){
                await alertError(this.$bvModal, '신규 비밀번호가 재입력 비밀번호와 일치 하지 않습니다.');
                return false;
              }

              if( this.newPassword.length < 6 || this.newPassword.length > 50){
                await alertError(this.$bvModal, '비밀번호는 6~50자로 입력해주세요');
                return false;
              }

              this.user['new_pwd'] = this.newPassword;
            }else{
              // 패스워드 입력 하지 않은 경우
              this.user['new_pwd'] = null;

            }

            this.user['origin_pwd'] = this.originPassword;

            this.saveUser();
          },


          async saveUser() {
            let r = null;

            try{
              r = await apiCall("PUT", '/api/user', this.user);
              if( r.code===200 ){
                await alertSuccess(this.$bvModal, '사용자 정보가 저장 되었습니다.');
              }else{
                await alertError(this.$bvModal, '사용자 정보저장 실패: '+r.message);
              }

            }catch(err){
              console.log( err );
              alert(err.message);
            }
          },


          async getUserInfo(){
                console.log("======================getUserInfo========================")
                let r = null;
                try{
                    r = await apiCall('get', '/api/user?user_no='+$user.user_no);
                    console.log("result--------->", r);
                    if( r.code===200){
                        this.user = r.result[0];
                    }

                }catch(err){
                    console.log( err );
                }



            }

        }
    }
</script>
